html {
   font-size : 15px !important;
}

html, 
body, 
.app { 
    height: 100vh;
    background: #fff;
    padding: 0;
    margin : 0;
    font-family:Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    line-height: 1.5;
    font-weight: 400;
    text-align: left;    
}

h1, h2, h3, h4, h5 {
   line-height: 1.28571429em;
   margin: calc(2rem - .14285714em) 0 1rem;
   font-weight: 700;
   padding: 0;
}

.login-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   height: 100vh;
   padding: 0 10px;
}

.login-container .app {
   flex: 0 0 400px;
}

.login-container .todolist {
   flex: 1;
   margin-left: 20px;
}

@media (max-width: 768px) {
   .login-container {
       flex-direction: column;
       justify-content: center;
   }
   .login-container .todolist {
       display: none;
   }
}

body { 
   font-size: 15px;
}

.app-header {
    background: rgb(52, 58, 64);
    height : 80px;
    padding : 15px;
}

.app-header h2 {
    color: #17a2b8 !important;
    font-size: 2rem;
    margin-left: 2rem;
}

.chart {
	height: 30px;
	float: left;
	margin: 0;
   transition: width 0.5s;
}

.withShadow {
	box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.20);
}

.green {
	background-color: #95c975;
}

.yellow {
	background-color: #ffdf40;
}

.darkred {
   background-color: #ed4c4c
}

.scale {
	width: 49%;
	display: inline-block;
	text-align: right;
}

.th-20 {
	width : 20%; 	
 }

  .th-50 {
  width : 50%;
 }

 .th-fixed-50 {
   width : 50px;
}
 .th-fixed-60 {
 	width : 60px;
 }

  .th-fixed-120 {
  width : 120px;
 }

 .th-fixed-180 {
 	width : 180px;
 }

  .th-fixed-160 {
  width : 160px;
 }

 .th-variant {
  white-space: nowrap;
 }

 .no-data { 
    color : #aaa;
    font-size: 2rem;
    margin-top: 100px;
 }  

 a.link {
    font-weight: 500;
    color: #333;
 }

 a.active {
    color: rgb(33, 133, 208);
 }

 .online {
    background-color: #95c975 !important;
 }

 .ui .table td { 
    vertical-align: middle;
 }

 .ui.menu .item {
    color : rgb (0, 0, 0, 0.8);
 }

 /* new design */
 .user-menu {
   font-size: 1.2rem;
   margin-right: 50px;
   color : #545454;
   flex-shrink: 0;
 }

 .links {
   font-size: 1.2rem;   
   color : #ddd;
   display: flex;
   justify-content: flex-end;
 }

 .text-info { 
    margin : 0;
 }

 .sidebar {
   position: fixed;	
   top: 0;
   bottom: 0;
   left: 0;
   z-index: 100;
   padding: 70px 0 0;
   box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
   }
   
   @media only screen and (max-width: 767.98px) {
       .sidebar {
       position : relative;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
      box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
      }
   }
   
   
   .sidebar-sticky {
     position: relative;
     top: 0;
     height: calc(100vh - 70px);
     padding-top: .5rem;
     padding-left: 1rem;
     overflow-x: hidden;
     overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */   
   }
   
   @supports ((position: -webkit-sticky) or (position: sticky)) {
     .sidebar-sticky {
       position: -webkit-sticky;
       position: sticky;
     }
   }

   .main-content {      
      padding-left: 25px;
      padding-bottom : 20px;
      padding-top: 10px;
      position: relative;
   }

   @media only screen and (max-width: 767.98px) {
      .main-content  {
         padding-left: 10px;
         padding-right: 10px;
     }
  }

   .content-scroll {
     height: calc(100vh - 80px); /* Set the height of the content area to the height of the viewport minus the height of the header (80px) */
     overflow-y: auto; /* Enable vertical scrolling */
    }

.sticky-button {
  float: right;
  margin-right: 50px;
}


   .th-20 {
      width : '20%'; 	
    }
   
     .th-50 {
     width : '50%';  
    }
   
    .th-fixed-60 {
       width : 60px;
    }
   
     .th-fixed-120 {
     width : 120px;
    }
   
    .th-fixed-180 {
       width : 180px;
    }
   
     .th-fixed-160 {
     width : 160px;
    }
   
    .th-variant {
     white-space: nowrap;
    }

    .center{
      text-align: center !important;      
     }

    .webicon {
      background-repeat: no-repeat;
      background-position : 5px center;
      padding-left : 35px;
    }		

    .app-icon {
      background-repeat: no-repeat;
      background-position : 5px center;
      padding-left : 35px;
     
      min-height : 16px;       
   }

   .online  {
      background-color : #95c975 !important;
      color : #fff !important;
     }
   
   .offline  {
     background-color : #bbb !important;
     color : #fff !important;     
   }    

   .paused  {
      background-color : #ff7843 !important;
      color : #fff !important;     
    }    
   
   .ui.icon.button {
      padding: 0.6em !important;
   }


   .blacklist-container {   
         display: -ms-flexbox;
         display: -webkit-flex;
         display: flex;
         -webkit-flex-direction: row;
         -ms-flex-direction: row;
         flex-direction: row;
         -webkit-flex-wrap: nowrap;
         -ms-flex-wrap: nowrap;
         flex-wrap: nowrap;
         -webkit-justify-content: flex-end;
         -ms-flex-pack: end;
         justify-content: flex-end;
         -webkit-align-content: center;
         -ms-flex-line-pack: center;
         align-content: center;
         -webkit-align-items: center;
         -ms-flex-align: center;
         align-items: center;
      }

.blacklist-title {
   -webkit-order: 0;
   -ms-flex-order: 0;
   order: 0;
   -webkit-flex: 1 1 auto;
   -ms-flex: 1 1 auto;
   flex: 1 1 auto;
   -webkit-align-self: auto;
   -ms-flex-item-align: auto;
   align-self: auto;
   width: 200px;
}

.blacklist-status {
   -webkit-order: 0;
   -ms-flex-order: 0;
   order: 0;
   -webkit-flex: 0 0 auto;
   -ms-flex: 0 0 auto;
   flex: 0 0 auto;
   -webkit-align-self: auto;
   -ms-flex-item-align: auto;
   align-self: auto;
   margin : 0 10px;
   }

.blacklist-remove {
   -webkit-order: 0;
   -ms-flex-order: 0;
   order: 0;
   -webkit-flex: 0 0 auto;
   -ms-flex: 0 0 auto;
   flex: 0 0 auto;
   -webkit-align-self: auto;
   -ms-flex-item-align: auto;
   align-self: auto;
   }

   .blacklist-status-container {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      width : 70px;
   }

.sidebar-title {
   margin : 7px;
   margin-left: 15px;
   font-size: 1rem;
   font-weight: 400;
   color : #777;
}

.sidebar-link {
   margin : 0 !important;
   padding-top : 8px !important; 
   font-size: 1.3rem !important;
}

.sidebar-icon {
   font-size : 1.4rem !important;
}

h3 { 
   margin-bottom: 1rem !important;
}

.login-subtitle {
   text-align: left;
   color : #555;
   text-align : left;
   font-size : 1.1rem;
   font-weight : 200;
   margin-bottom : 20px;
   margin-top : 15px;   
}

.login-title {
   color : #888;
   text-align : center;
   font-size : 1.6rem;
   font-weight : 200;
   margin-bottom : 20px;
   margin-top : 15px;
}


.save-button {
   position: absolute;
   top: 30px; /* Adjust the vertical position of the button */
   left: 305px; /* Adjust the horizontal position of the button */
   z-index: 1000; /* Ensure the button stays above other elements */
 }

.main-content h2 {
   font-size: 1.7rem;
   margin : 0;
   margin-left : 10px;
   margin-top: 10px;
   margin-bottom: 20px;
   font-weight: 500;
   color : #666;
}

.main-content h3 {
   font-size: 1.2rem;
   font-weight: 400;
   color :#555;
   margin-top: 26px;
}

.navbar { 
   padding: 5px 10px !important;
   box-shadow: 0 1px 0px rgba(28, 72, 110, 0.13) !important;
   background-color: #fff !important;
}

h2.header-logo {
   color : #545454;
   font-weight: 500;
   vertical-align: baseline;
   margin : 0;
   margin-top: 3px;
   font-size: 1.5rem;
   display: flex;
   align-items: center;
   flex-shrink: 0;
}

h2.header-logo img {
   margin: -2px 10px 0 5px;
}

h2 .header-version {
   margin-left: 10px;
   font-size : 1rem;
}

textarea {
   font-family:Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
   min-height: 50px;
}

.sidebar h3 {
   font-size: 1.2rem;
}

.ui.menu {
   margin-top : 6px;
   margin-bottom: 10px;
}


.flex-box {
   display : flex;
   flex-direction : row;
   flex-flow: row wrap;
   justify-content: left;   
}

@media only screen and (max-width: 1219.98px) {
   .flex-box.web-block {
      flex-direction: column;      
   }
}



.main-container {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   padding-top: 65px;
}

.main-sidebar {
   flex: 1 0 200px;
   padding: 0;
   min-width : 300px;   
   box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
   background-color: #f8f9fa !important;
   }     


.main-content { 
   flex : 12;
   padding-right: 15px;
   min-width : 400px;
}

@media only screen and (max-width: 767.98px) {
   .main-container {
      display: block;
      padding-top: 30px;

   }
}

.messageBox {
   color : darkblue;
   font-size: 1.2rem;
   padding: 10px;
   margin : 10px;
   font-weight: 700;
}

.trunc {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.ui.selection.list .list>.item,
.ui.selection.list>.item {
   padding : 0.2em;
 }

 .ui.toggle.checkbox.red input:checked~.box:before,
.ui.toggle.checkbox.red input:checked~label:before {
    background-color:#eb2513 !important
}

.ui.toggle.checkbox.red input:focus:checked~.box:before,
.ui.toggle.checkbox.red input:focus:checked~label:before {
    background-color: #eb2513 !important
}



.downloadPage .center    {
   display: flex;
   flex-direction: row;
   justify-content: center;
   margin: 10px;
}
.downloadPage h1 {
   font-weight: 400;
   font-size: 30px;
   text-align: center;
   color: #666;
   max-width: 700px;
}

.downloadPage img {
   height: 240px;
}

.downloadPage .screenshots {
   display: flex;
   flex-direction: row;
   justify-content: center; 
   flex-wrap: wrap;  
}

.downloadPage .screenshots .step {
   flex: 0 1 0px;
   margin : 15px;

}

.downloadPage .screenshots .step::after {
   content: counter(step);
   position: absolute;
   color: #2AABCB;
   right: 10px;
   top: 3px;
   font-size: 24px;
   font-weight: 700;
}

.downloadPage .screenshots .step::before {
   content: '';
   position: absolute;
   right: -49px;
   top: -49px;
   height: 0;
   width: 0;
   border: 48px solid transparent;
   border-bottom-color: transparent;
   border-bottom-style: solid;
   border-bottom-width: 48px;
   border-bottom: 48px solid #f3f4f5;
   -webkit-transform: rotate(45deg);
   transform: rotate(45deg);
}

.downloadPage .screenshots .step {
   counter-increment: step;
   position: relative;
   box-shadow: 0 0 4px 1px rgba(0,0,0,0.1);
   border: 2px solid #ffffff;
   padding: 30px;
   height: 100%;
}

.downloadPage .button {
   font-size: 22px;   
   margin: 15px;
   display: inline-block;
   box-sizing: border-box;
   text-decoration: none;
   color: #fff;   
   background: #72AB34;   
   border-radius: 8px;
   font-size: 24px;
   text-align: center;
   padding: 22px 15px;
   min-width: 180px;
   line-height: 20px;
   cursor: pointer;
   vertical-align: middle;   
}

.downloadPage .button:hover {
   outline: 0;
   background: #64982D;
   box-shadow: 0 0 0 4px rgba(165,219,108,0.7);   
}

.downloadPage .text {
   font-size: 20px;
   color: #666;
}

.downloadPage .description {
   font-size: 18px;
   color: #666;
   margin-top: 10px;
}

.downloadPage .link {
   padding: 10px;
   background-color: #f5f5f4;
   font-size: 22px;
   color: #666;
}

.ui.form a, .webicon a {
   color: #4183c4 !important;
}

.search-websites.ui.search .prompt {
   border-radius: 0;
   width : 100%;
}

.reason {
   text-transform: capitalize;
}

@keyframes highlight {
   0% {
       background: #ffff99; 
   }
   100% {
       background: none;
   }
}

.highlight {
   animation: highlight 2s;
}
   
.messageRoot {
   left: 50%;
    right: auto;
    top: 64px;   
    display: flex;
    z-index: 1400;
    position: fixed;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.messageRoot {
   transform: translateX(-50%); /* Центрирование по горизонтали */
   min-height: 100px; /* Пример минимальной высоты */
   min-width: 200px; /* Пример минимальной ширины */
}

   
 a .help-icon {
   font-size : 1rem;
   margin-left: 20px;
   margin-top : 2px;
   color: rgba(0,0,0, 0.4);
 }

 a:hover .help-icon {
    color: rgb(65, 110, 182);
 }
   
 
 .block-icon {
   font-size : 1.2em !important;
   color:  rgb(220, 53, 69) !important;
 }

 .limit-icon {
   font-size : 1.2em !important;
   color : rgb(245, 194, 66) !important;
 }

 .allow-icon {
   font-size : 1.2em !important;
   color:  #28a745 !important;

  }

.zoom-wrapper {
   transform: scale(0.9);
   transform-origin: top left;
}


.activeTitle {
   background-color: rgb(33, 133, 208) !important;
   color: white !important;
   border-radius: 10px !important;
 }

 .my-badge {
   margin-left: 10px;
   font-size: 0.6em;
   background-color: #f0f0f0;
   padding: 4px;
   border-radius: 8px;
   color: #636363;
   
   font-weight: 500;
   display: inline-block;
   line-height: 1;
   vertical-align: top; /* Move the span to the top */   

   padding: 0.325rem 0.5rem;
   border-radius: 0.75rem;   
 }

 .date-box {
	text-align: right;
	font-size: 0.8em;
	margin-right: 50px;
   vertical-align: middle;   
  }

  .date-change {
	cursor: pointer;
  }
  
  .date-change:hover {
	color: #444;
  }

 .tab-button {
   cursor: pointer;
   font-size: 0.7em;
	border: 1px solid #bbb;
	border-radius: 0px;   
   padding: 8px 8px;  
 }

 .tab-button.day {
   border-top-left-radius: 4px;
   border-bottom-left-radius: 4px;   
   margin-left : 0px;
 }

 .tab-button.week {
   border-top-right-radius: 4px;
   border-bottom-right-radius: 4px;  
   margin-right: 10px; 
 }


 .tab-button:hover { 
   background-color: #eee;
 }

 .tab-button.active {
   background-color: #10334594;
   color : #fff;
 }

.worktime { background-color: rgb(102, 204, 153)}         
.totaltime { background-color: #95c975;}
.wastedtime { background-color: #ffdf40;}
.idletime { background-color: #d0d0d0}

.yellow-dark { color : #544709;}

.chart {
   container-type: inline-size; 
 }

 .percentage-label {
   font-size: 0.85em;
   display: block;
   margin-top: 4px;
 }

 @container (max-width: 40px) {
   .percentage-label {
      display : none;
   }
 }

#wizard .main-text {
   font-size: 1.1em;
   padding-top: 10px;
   padding-bottom: 10px;
}

 #wizard .mode {
   cursor: pointer;
   transition: box-shadow 0.3s ease;
   height: auto; /* Убираем фиксированную высоту, чтобы позволить контенту определять высоту */
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   text-align: left;
   padding: 30px; /* Увеличенные отступы для большего пространства вокруг контента */
   margin-bottom: 20px; /* Добавляем нижний отступ между сегментами */
 }

 #wizard .user-box {
   cursor: pointer;
   transition: box-shadow 0.3s ease;
   height: auto; /* Убираем фиксированную высоту, чтобы позволить контенту определять высоту */
   display: flex; /* Включаем flexbox */
   flex-direction: column; /* Устанавливаем основное направление как колонку */
   align-items: center; /* Центрируем содержимое по горизонтали */
   justify-content: center; /* Центрируем содержимое по вертикали */
   text-align: center; /* Центрируем текст */
   cursor: pointer;
   transition: box-shadow 0.3s ease;
   max-width: 150px;
 }

 #wizard .column-age { 
   padding-left: 5px !important;
   padding-right: 5px !important;
 }

 #wizard .age {
   cursor: pointer;
   transition: box-shadow 0.3s ease;
   height: auto; /* Убираем фиксированную высоту, чтобы позволить контенту определять высоту */
   display: flex; /* Включаем flexbox */
   flex-direction: column; /* Устанавливаем основное направление как колонку */
   align-items: center; /* Центрируем содержимое по горизонтали */
   justify-content: center; /* Центрируем содержимое по вертикали */
   text-align: center; /* Центрируем текст */
   cursor: pointer;
   transition: box-shadow 0.3s ease;
   padding: 0.5em 0.5em;
 }
 
 #wizard .custom-segment:hover {
   box-shadow: 0 0 15px rgba(33, 133, 208, 0.4);
 }
 
 #wizard .selected {
   background-color: #e8f3ff; /* Более светлый и бледный оттенок синего для выбранной опции */
   border-color: #2185d0;
   border-width: 2px;
   color: #333;
 }
 
 #wizard .disclaimer {
   color: #333;
   font-size: 0.9em;
   margin-top: 30px; /* Увеличиваем отступ сверху для большего пространства до примечания */
   margin-bottom: 30px;
 }
 
 #wizard h2:after {
   content: '';
   display: block;
   height: 3px; /* Высота полосы */
   background-color: #2185d0; /* Цвет полосы */
   margin-top: 20px; /* Отступ сверху */
 }

 #wizard .custom-segment p {
   line-height: 1.2; /* Уменьшаем расстояние между строками */
 }

 #wizard .next-button {
   position: absolute;
   right: 30px; /* Отступ справа */
   bottom: 30px; /* Отступ снизу */
 }

 #wizard .feature-item {
   line-height: 1.8; /* Увеличиваем межстрочное расстояние */
 }

 .custom-toggle .ui.toggle.checkbox {
   transform: scale(0.9); /* Уменьшает размер всего компонента чекбокса */
   transform-origin: center; /* Гарантирует, что трансформация происходит от центра */
   margin-top: 1px; /* Добавляет отступ сверху */
   margin-bottom: 1px; /* Добавляет отступ снизу */   
   padding-top: 10px;
}

 #user-settings .header {
   font-weight: 400 !important;
   text-align: center !important;   
   padding : 3px;
   border-top: 0;
   border-bottom: 0;
 }

 #user-settings th {
   border : 0 !important;
 }

 #user-settings td { 
   border : 0; 
 }

 #user-settings .feature {
   font-size: 17px;
   padding-left : 25px;
 }



 #user-settings .username {
   font-weight: 400 !important;
   vertical-align: middle;
   padding-top: 15px;
 }

 #user-settings .center {
   vertical-align: middle;
 }

 #user-settings .report {
   /* background-color: #f0f7ff;  */
   border-left: .2em solid #2185d0;   
 }
 
 #user-settings .control {
  /* background-color: #fffaec; */  
  border-left: .2em solid #fbbd08;  
 }

 #user-settings .screen-time {
   /* background-color: #fff0ec;  */
   border-left: .2em solid #f2711c;
 }

 .MuiAlert-root {
   font-size: 1rem !important;
 }

 .customBadge {
   position: absolute;
   width: 13px;
   height: 13px;
   bottom: -2px;
   right: 0px;
   border-radius: 50%;
   background-color: #4B9AD6; 
   border: 2px solid white; 
 }

 .iconBadge {
   position: absolute;
   width: 24px; /* Размер круга */
   height: 24px; /* Размер круга */
   bottom: -8px; /* Расположение с нижнего края */
   right: -8px; /* Расположение с правого края */
   background-color: #4B9AD6; /* Цвет фона круга */
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   border: 2px solid white; /* Отделяем круг от аватара */
   cursor: pointer;
}

.subscribe-reminder {
   position: fixed; /* Фиксированное положение */
   bottom: 0; /* Прижать к низу экрана */
   left: 0;
   width: 100%; /* Полная ширина */
   background-color: #4B9AD6; /* Зелёный фон */
   color: white; /* Белый текст */
   text-align: center; /* Текст по центру */
   padding: 10px 0; /* Внутренние отступы сверху и снизу */
   z-index: 1000; /* Убедиться, что полоса будет над другими элементами */
 }



 @media (max-width: 767px) {
   .user-menu {
      margin-right : 80px;
   }
   .user-menu .dropdown span span {
     display: none; /* Скрывает второй span внутри .dropdown */
   }
 }

 @media (max-width: 767px) {
   .header-logo span {
     display: none;
   }
 }

 @media (max-width: 1000px) {
   .time-chart {
     display: none !important;
   }

   .th-name {
      width : auto !important;
    } 
 }

 .th-name {
   min-width: 50px;
   width : 30%;
 }

.th-dots {
   width : 40px;
}

.th-time {
   width : 140px;
}

.avatar-grid {
   display: grid;
   grid-template-columns: repeat(8, 32px);
   gap: 10px;
   justify-content: center;
   margin-bottom: 20px;
}

.avatar-item {
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 32px;
   cursor: pointer;
   width: 32px;
   height: 32px;
   border-radius: 50%;
   transition: transform 0.2s, box-shadow 0.2s;

}

.avatar-item:hover {
   transform: scale(1.1);
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
   background-color: #f0f0f0; /* для визуального выделения */
}

.sidebar-link:hover { 
   transform: scale(1.05); 
}

.avatar-image {
   width: 100%;
   height: 100%;
   border-radius: 50%;
}

.todolist {
   background-color: #00AEEF;
   height: 100vh;
   color: white;
   text-align: left;
   padding: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.todolist h2 {
   font-size: 1.7rem !important;
   margin: calc(2rem - .14285714em) 0 1rem !important;
}

.todolist-container {
   height: 400px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.todolist-item {
   margin-left: -50px;
   padding-left: 120px;
   max-width: 600px;
}

.todolist-number {
   position: fixed;
   float: left;
   margin-left: -60px;
   margin-top: 10px;
   font-size: 4em;
}

.todolist-description {
   font-size: 1.1em;
   margin-top: -10px;
}

.todolist-link {
   color: white;
   text-decoration: underline;
}

sub { 
   font-size : 0.86em;
   bottom: 0;
   margin-left: 3px;
 }

 .date-col sub {
   font-size: 0.7em;
   font-weight: 700;
   color: #555;
   text-transform: uppercase;
 }

 .navbar {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
   padding: .5rem 1rem;
 }

 .navbar {
   padding: 5px 10px !important;
   box-shadow: 0 1px 0px rgba(28, 72, 110, 0.13) !important;
   background-color: #fff !important;
 }

 .fixed-top {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   z-index: 1030;
 }

 @media (min-width: 768px) {
   .flex-md-nowrap {
     flex-wrap: nowrap !important;
   }
 }

 .ml-auto, .mx-auto {
   margin-left: auto !important;
 }
