.hours-header {
  display: flex;
  margin-left: 50px;
  margin-bottom: 5px;
}

.halves-day {
  display: flex;
  margin-left: 50px;
}

.half-day {
  width : 240px;
  margin-left : 5px;
  font-size: 0.7 rem;
  font-weight: 700;
  color: #555;
}

.hour-item {
  width : 20px;
  font-size: 0.8rem;
  text-align: center;
  color: #555;
}

.item-root {
	width: 100%;
	display: flex;
	position: relative;
	box-sizing: border-box;
	text-align: left;
	align-items: center;
	justify-content: flex-start;
  margin : 0;
}

.week-title {
  display : table-cell;
  vertical-align: middle;
  width :  60px;
  height : 20px;
  font-size: 0.8rem;
  color: #555;
}


.cell {
  width : 20px;
  height: 20px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;  
}

.cell-allow {
  background-color: #28a745;
}

.cell-block {
  background-color: #dc3545;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.schedule {
  margin-bottom: 20px;
}
			