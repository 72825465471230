.dashboard 	.header { 
	height : 80px;
	padding-left: 100px;
	padding-top: 15px;
	cursor: pointer;
	margin-bottom : 10px;
	margin-top: 10px;
	border-radius: 5px;
}

@media only screen and (max-width: 767px) {
	.dashboard 	.header {
		padding-left: 10px;
   }
}

.header-title {
	color : #fff;
	font-weight: 700;
	font-size: 1.2rem;
}

.header-subtitle {
	color : #fff;
	margin-top : 5px;
	font-size: 1.1rem;
}

div.status-active {
	background-color: #4bbf60;
	background-image: url(../../images/active.png);
	background-repeat: no-repeat;
	background-position: 20px center;
}

div.status-inactive {
	background-color: #FF9423; /* #e99615; */ 
	background-image: url(../../images/inactive.png);
	background-repeat: no-repeat;
	background-position: 20px center;
}

div.status-expired {
	background-color: #FF9423; /* #e99615; */ 
	background-image: url(../../images/inactive.png);
	background-repeat: no-repeat;
	background-position: 20px center;	
}



div.status-expired a {
	color : #fff;
}




li {
	list-style: none;
}

li.category {
  padding-left: 60px;	
  background-repeat: no-repeat;
  background-position: 5px center; 
  width : 80%;  	  
  border-radius: 4px;
  height : 54px;   
  color : #aaa;
  margin-bottom: 5px;
}


a.category {
  border-radius: 4px;
  font-weight: 500;
  height : 54px; 
  font-size: 1.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color : inherit;
}

.category:hover  {
  color: #ddd;
  background-color : #4f697c;
  text-decoration: none;
}

li.title { 
  	font-weight: 700;	
	color : #ddd;
	font-size: 1.1rem;	
	margin-bottom: 10px;
 }

 .chartComputer {
	display: flex;
	justify-content: space-between;
	width: 480px;
	height: 250px;
	padding: 20px;
	box-sizing: border-box;
	background-color: #ffffff;
	 border: 1px solid #e0e0e0;
	border-radius: 8px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
	padding-bottom: 30px;
	position: relative;
  }
  
  .chart-bar {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	flex-grow: 1;
	margin: 0 5px;
	position: relative;
	bottom: 0;
  }
  
  .chart-bar-segment {
	width: 80%;
	transition: height 0.5s;
  }
  
  .chart-bar-active {
	background-color: #95c975;
	box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, .2);
  }
  
  .chart-bar-wasted {
	background-color: #ffdf40;
	box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, .2);	
  }

  .chart-bar-idle {
	background-color: #ddd;	
  }  
  
  .chart-bar-label {
	margin-top: 5px;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	position: absolute;
	bottom: -20px;
	color: #444;
  }
  
  .chart-y-axis {
	height: 100%;
	margin-right: 10px;
	margin-top: -10px; /* top offset */
	position: relative;
	width: 60px;
  }

  .chart-y-axis-label {
	font-size: 0.8em;
	color : #444;
  }

  .chart-overlay {
	width: 390px;
	height: 198px;	
    background-color: transparent;
    position: absolute;
    z-index: 2;
	left: 75px;	
	pointer-events: none;	
  }
  
  .line-overlay {
    width: 100%;
    height: 1px;
    position: absolute;
    border-top: 1px dashed rgba(56, 56, 94, 0.4); /* Dashed line with 50% opacity */
}

.app-chart {
	width: 480px;
	height: 250px;	
	display: flex;
	flex-direction: column;
	padding: 20px;
	box-sizing: border-box;
	background-color: #ffffff;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .app-item {
	display: flex;
	width : 100%;
	justify-content: space-between;
	align-items: flex-start;
	height: 45px;
  }
  
  .app-time {
	font-weight: 500;
	width: 100px;
	text-align: right;
	font-size: 0.95em;
	color: #444;
  }

  .app-link {
	float: right;
	font-size: 0.7em;
	margin-left: 20px;
	margin-top: 5px;
  }

  .dashboard h3 {
	padding-left: 20px;
  }

  .label {
	background-color: #eee; /* set gray background */
	border-radius: 4px; /* set 4px radius for rounded corners */
	color: #777; /* set text color */
	padding: 0.2em 0.5em; /* add padding to provide space around text */
	font-size: 0.6em;
	font-weight: 500;
	position: relative; /* enable positioning */
	top: -10px; /* move the element up by 10 pixels */
  }

  @media (max-width: 767px) {
	div.status-active, div.status-inactive, div.status-expired {
		background-image: none;
   }
}

